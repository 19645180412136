<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        What is the
        <stemble-latex content="pH" />
        of a solution made by dissolving
        <number-value :value="mass" unit="\text{g}" />
        of sodium carbonate,
        <chemical-latex content="Na2CO3," />
        in enough water to make
        <stemble-latex content="$0.400\,\text{L}$" />
        of solution? Note that
        <stemble-latex content="$\text{K}_\text{a} = 4.7 \times 10^{-11}$" />
        for
        <chemical-latex content="HCO3-" />
      </p>

      <calculation-input
        v-model="inputs.studentAnswer"
        prepend-text="$\text{pH:}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'ChemSIU131_Task19',
  components: {
    ChemicalLatex,
    CalculationInput,
    NumberValue,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswer: null,
      },
    };
  },
  computed: {
    mass() {
      return this.taskState.getValueBySymbol('mass').content;
    },
  },
};
</script>
